<template>
  <div class="container">
    <div class="title">Règlement intérieur</div>
    <div class="box">
      <div class="subtitle">EDITÉ PAR :<br>
        BAKJY SARL<br>
        23 RUE DES LOMBARDS<br>
        75004 PARIS<br>
        SOCIÉTÉ PAR ACTIONS SIMPLIFIÉE AU CAPITAL DE 100 000 EUROS<br>
        SIREN 821408408 RCS PARIS<br>
        TVA INTRACOMMUNAUTAIRE FR85821408408<br>
        TEL : 06 77 42 86 71<br>
        DIRECTEUR DE LA PUBLICATION : MADAME ANAÏS EVRARD – ANAIS@BAKJY.COM – 06 77 42 86 71<br>
        HÉBERGÉ PAR :<br>
        OVH<br>
        2 RUE KELLERMANN – 59100 ROUBAIX<br>
        SOCIÉTÉ PAR ACTIONS SIMPLIFIÉE UNIPERSONNELLE<br>
        SIREN 424 761 419 RCS LILLE<br>
        TEL : 33 9 55 00 66 33
      </div>
    </div>
    <div class="box">
      <p class="subtitle">article 1 :</p>
      <p><strong>Personnel assujetti</strong>
        <br><br>
        Le présent règlement s'applique à tous les stagiaires. Chaque stagiaire est censé accepter les termes du présent
        contrat lorsqu'il suit une formation dispensée par CONCILIA.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 2 :</p>
      <p><strong>Conditions générales</strong>
        <br><br>
        Toute personne en stage doit respecter le présent règlement pour toutes les questions relatives à l'application
        de la réglementation en matière d'hygiène et de sécurité, ainsi que les règles générales et permanentes
        relatives à la discipline.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 3 :</p>
      <p><strong>Règles générales d'hygiène et de sécurité</strong>
        <br><br>
        Chaque stagiaire doit veiller à sa sécurité personnelle et à celle des autres en respectant, en fonction de sa
        formation, les consignes générales et particulières de sécurité en vigueur sur les lieux de stage, ainsi qu'en
        matière d'hygiène.
        <br>
        Toutefois, conformément à l'article R.6352-1 du Code du Travail, lorsque la formation se déroule dans une
        entreprise ou un établissement déjà doté d'un règlement intérieur, les mesures d'hygiène et de sécurité
        applicables aux stagiaires sont celles de ce dernier règlement.
        <br>
        Par ailleurs, les stagiaires envoyés en entreprise dans le cadre d'une formation, sont tenus de se conformer aux
        mesures d'hygiène et de sécurité fixées par le règlement intérieur de l'entreprise.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 1 :</p>
      <p><strong>Personnel assujetti</strong>
        <br><br>
        Le présent règlement s'applique à tous les stagiaires. Chaque stagiaire est censé accepter les termes du présent
        contrat lorsqu'il suit une formation dispensée par CONCILIA.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 4 :</p>
      <p><strong>Maintien en bon état du matériel</strong>
        <br><br>
        Chaque stagiaire a l'obligation de conserver en bon état le matériel qui lui est confié en vue de sa formation.
        Les stagiaires sont tenus d'utiliser le matériel conformément à son objet : l'utilisation du matériel à d'autres
        fins, notamment personnelles est interdite.
        <br>
        Suivant la formation suivie, les stagiaires peuvent être tenus de consacrer le temps nécessaire à l'entretien ou
        au nettoyage du matériel.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 5 :</p>
      <p><strong>Utilisation des machines et du matériel</strong>
        <br><br>
        Les outils et les machines ne doivent être utilisés qu'en présence d'un formateur et sous surveillance.
        <br>
        Toute anomalie dans le fonctionnement des machines et du matériel et tout incident doivent être immédiatement
        signalés au formateur qui a en charge la formation suivie.
        <br>
        Consigne d'incendie
        <br>
        Les consignes d'incendie et notamment un plan de localisation des extincteurs et des issues de secours sont
        affichés dans les locaux de l'organisme de manière à être connus de tous les stagiaires.
        <br>
        Des démonstrations sont prévus pour vérifier le fonctionnement du matériel de lutte contre l'incendie et les
        consignes de prévention d'évacuation.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 6 :</p>
      <p><strong>Accident</strong>
        <br><br>
        Tout accident ou incident survenu à l'occasion ou en cours de formation doit être immédiatement déclaré par le
        stagiaire accidenté ou les personnes témoins de l'accident, au responsable de l'organisme.
        <br>
        Conformément à l'article R 6342-3 du Code du Travail, l'accident survenu au stagiaire pendant qu'il se trouve
        dans l'organisme de formation ou pendant qu'il s'y rend ou en revient, fait l'objet d'une déclaration par le
        responsable du centre de formation auprès de la caisse de sécurité sociale.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 7 :</p>
      <p><strong>Boissons alcoolisées</strong>
        <br><br>
        Il est interdit aux stagiaires de pénétrer ou de séjourner en état d'ivresse dans l'organisme ainsi que d'y
        introduire des boissons alcoolisées.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 8 :</p>
      <p><strong>Interdiction de fumer</strong>
        <br><br>
        En application du décret n° 92-478 du 29 mai 1992 fixant les conditions d'application de l'interdiction de fumer
        dans les lieux affectés à un usage collectif, il est interdit de fumer dans les salles de cours et dans les
        ateliers.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 9 :</p>
      <p><strong>Horaires - Absence et retards</strong>
        <br><br>
        Les horaires de stage sont fixés par la Direction ou le responsable de l'organisme de formation et portés à la
        connaissance des stagiaires lors de l’envoi des convocations. Les stagiaires sont tenus de respecter ces
        horaires de stage sous peine de l'application des dispositions suivantes:
        <br><br>
        · En cas d'absence ou de retard au stage, les stagiaires doivent avertir le formateur ou le responsable
        pédagogique de l'organisme qui à en charge la formation et s'en justifier. Par ailleurs, les stagiaires ne
        peuvent s'absenter pendant les heures de stage, sauf circonstances exceptionnelles précisées par la Direction ou
        le responsable de l'organisme de formation de l'organisme.
        <br><br>
        · Lorsque les stagiaires sont des salariés en formation dans le cadre du plan de formation, l'organisme doit
        informer préalablement l'entreprise de ces absences. Toute absence ou retard non justifié par des circonstances
        particulières constitue une faute passible de sanctions disciplinaires.
        <br><br>
        · En outre, pour les stagiaires demandeurs d'emploi rémunérés par l'État ou une région, les absences non
        justifiées entraîneront, en application de l'article R 6341-45 du Code du Travail, une retenue de rémunération
        proportionnelle à la durée des dites absences.
        <br><br>
        Par ailleurs, les stagiaires sont tenus de remplir ou signer obligatoirement et régulièrement, au fur et à
        mesure du déroulement de l'action, l'attestation de présence, et en fin de stage le bilan de formation ainsi que
        l'attestation de suivi de stage.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 10 :</p>
      <p><strong>Accès à l'Organisme</strong>
        <br><br>
        Sauf autorisation expresse de la Direction ou du responsable de l'organisme de formation, les stagiaires ayant
        accès à l'organisme pour suivre leur stage ne peuvent:
        <br><br>
        · Y entrer ou y demeurer à d'autres fins;
        <br>
        · Y introduire, faire introduire ou faciliter l'introduction de personnes étrangères à l'organisme, ni de
        marchandises destinées à être vendues au personnel ou au stagiaires.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 11 :</p>
      <p><strong>Tenue et comportement</strong>
        <br><br>
        Les stagiaires sont invités à se présenter à l'organisme en tenue décente et à avoir un comportement correct à
        l'égard de toute personne présente dans l'organisme.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 12 :</p>
      <p><strong>Information et affichage</strong>
        <br><br>
        La circulation de l'information se fait par l'affichage sur les panneaux prévus à cet effet. La publicité
        commerciale, la propagande politique, syndicale ou religieuse sont interdites dans l'enceinte de l'organisme.
      </p>
    </div>

    <div class="box">
      <p class="subtitle">article 13 :</p>
      <p><strong>Responsabilité de l'organisme en cas de vol ou endommagement de biens personnels des
        stagiaires</strong>
        <br><br>
        L'organisme décline toute responsabilité en cas de perte, vol ou détérioration des objets personnels de toute
        nature déposés par les stagiaires dans son enceinte (salle de cours, ateliers, locaux administratifs, parcs de
        stationnement, vestiaires ...).</p>
    </div>

    <div class="box">
      <p class="subtitle">article 14 :</p>
      <p><strong>Sanction</strong>
        <br><br>
        Tout manquement du stagiaire à l'une des prescriptions du présent règlement intérieur pourra faire l'objet d'une
        sanction.
        <br><br>
        Constitue une sanction au sens de l'article R 6352-3 du Code du Travail toute mesure, autre que les observations
        verbales, prises par le responsable de l'organisme de formation de l'organisme de formation ou son représentant,
        à la suite d'un agissement du stagiaire considéré par lui comme fautif, que cette mesure soit de nature à
        affecter immédiatement ou non la présence de l'intéressé dans le stage ou à mettre en cause la continuité de la
        formation qu'il reçoit.
        <br><br>
        Selon la gravité du manquement constaté, la sanction pourra consister:
        <br><br>
        · Soit en un avertissement;
        <br><br>
        · Soit en un blâme ou un rappel à l'ordre;
        <br><br>
        · Soit en une mesure d'exclusion définitive (il est rappelé que dans la convention passée par l'organisme avec
        l'État ou la Région, des dispositions particulières sont définies en cas d'application des sanctions énoncées
        ci-dessus).
        <br><br>
        Les amendes ou autres sanctions pécuniaires sont interdites.
        <br><br>
        le responsable de l'organisme de formation de l'organisme doit informer de la sanction prise:

        <br><br>
        · L'employeur, lorsque le stagiaire est un salarié bénéficiant d'un stage dans le cadre du plan de
        formation en
        entreprise;
        <br><br>
        · L'employeur et l'organisme paritaire qui a pris à sa charge les dépenses de la formation, lorsque le stagiaire
        est un salarié bénéficiant d'un stage dans le cadre d'un congé de formation. En application du décret n° 92-478
        du 29 mai 1992 fixant les conditions d'application de l'interdiction de fumer
        dans les lieux affectés à un usage collectif, il est interdit de fumer dans les salles de cours et dans les
        ateliers.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 15 :</p>
      <p><strong>Procédure disciplinaire</strong>
        <br><br>
        Les dispositions qui suivent constituent la reprise des articles R 6352-4 à R 6352-8 du Code du Travail.
        <br><br>
        Aucune sanction ne peut être infligée au stagiaire sans que celui-ci ait été informé au préalable des griefs
        retenus contre lui.
        <br><br>
        Lorsque le responsable de l'organisme de formation ou son représentant envisagent de prendre une sanction qui a
        une incidence, immédiate ou non, sur la présence d'un stagiaire dans une formation, il est procédé ainsi qu'il
        suit :
        <br><br>
        · Le responsable de l'organisme de formation ou son représentant convoque le stagiaire en lui indiquant l'objet
        de cette convocation.
        <br><br>
        · Celle-ci précise la date, l'heure et le lieu de l'entretien. Elle est écrite et est adressée par lettre
        recommandée ou remise à l'intéressé contre décharge.
        Au cours de l'entretien, le stagiaire peut se faire assister par une personne de son choix, stagiaire ou salarié
        de l'organisme de formation.
        <br><br>
        · La convocation mentionnée à l'alinéa précédent fait état de cette faculté. Le responsable de l'organisme de
        formation ou son représentant indique le motif de la sanction envisagée et recueille les explications du
        stagiaire. Dans le cas où une exclusion définitive du stage est envisagée et où il existe un conseil de
        perfectionnement, celui-ci est constitué en commission de discipline, où siègent les représentants des
        stagiaires.
        <br><br>
        · Il est saisi par le responsable de l'organisme de formation ou son représentant après l'entretien susvisé et
        formule un avis sur la mesure d'exclusion envisagée.
        <br><br>
        · Le stagiaire est avisé de cette saisine. Il est entendu sur sa demande par la commission de discipline. Il
        peut, dans ce cas, être assisté par une personne de son choix, stagiaire ou salarié de l'organisme. La
        commission de discipline transmet son avis au Directeur de l'organisme dans le délai d'un jour franc après sa
        réunion.
        <br><br>
        · La sanction ne peut intervenir moins d'un jour franc ni plus de quinze jours après l'entretien ou, le cas
        échéant, après la transmission de l'avis de la commission de discipline. Elle fait l'objet d'une décision écrite
        et motivée, notifiée au stagiaire sous la forme d'une lettre qui lui est remise contre décharge ou d'une lettre
        recommandée.
        <br><br>
        Lorsque l'agissement a donné lieu à une sanction immédiate (exclusion, mise à pied), aucune sanction définitive,
        relative à cet agissement ne peut être prise sans que le stagiaire ait été informé au préalable des griefs
        retenus contre lui et éventuellement que la procédure ci-dessus décrite ait été respectée.</p>
    </div>

    <div class="box">
      <p class="subtitle">article 16 :</p>
      <p><strong>Entrée en application</strong>
        <br><br>
        Le présent règlement intérieur entre en application à compter du 1er avril 2023.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reglement"
}
</script>

<style scoped>
.container {
  margin-top: 2rem;
}
</style>