<template>
  <Reglement></Reglement>
</template>

<script>
import Reglement from "@/components/Reglement";

export default {
  name: "ReglementInterieur",
  components: {
    Reglement,
  }
}
</script>

<style scoped>

</style>