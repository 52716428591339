<template>
  <div class="container">
    <div class="title">MENTIONS LEGALES</div>
    <div class="box">
      <div class="subtitle">EDITÉ PAR :<br>
        BAKJY SARL<br>
        23 RUE DES LOMBARDS<br>
        75004 PARIS<br>
        SOCIÉTÉ PAR ACTIONS SIMPLIFIÉE AU CAPITAL DE 100 000 EUROS<br>
        SIREN 821408408 RCS PARIS<br>
        TVA INTRACOMMUNAUTAIRE FR85821408408<br>
        TEL : 06 77 42 86 71<br>
        DIRECTEUR DE LA PUBLICATION : MADAME ANAÏS EVRARD – ANAIS@BAKJY.COM – 06 77 42 86 71<br>
        HÉBERGÉ PAR :<br>
        OVH<br>
        2 RUE KELLERMANN – 59100 ROUBAIX<br>
        SOCIÉTÉ PAR ACTIONS SIMPLIFIÉE UNIPERSONNELLE<br>
        SIREN 424 761 419 RCS LILLE<br>
        TEL : 33 9 55 00 66 33
      </div>
    </div>
    <div class="box">
      <p class="subtitle">1. Droit de Propriété Intellectuelle :</p>
      <p>Le présent site (ci-après « le Site ») ainsi que l’ensemble de son contenu (et notamment les photos, logos,
        marques et information de toute nature y figurant) est protégé par le droit d’auteur.
        <br><br>
        BAKJY SARL (ci-après « l’Editeur »), concède aux internautes une simple autorisation de visualisation qui exclut
        notamment la réutilisation de tout ou partie du contenu du Site pour quelque cause que ce soit. L’autorisation
        de reproduction n’est concédée que sous forme numérique sur l’ordinateur qui sert à la consultation du Site à
        des fins de visualisation des pages consultées par le logiciel de navigation de l’internaute.<br>
        Tous les droits de reproduction sont réservés y compris pour les documents téléchargeable (logos, photos,
        informations de toute nature ou autre). Les documents à télécharger sont également protégés par le droit
        d’auteur; les limites de l’utilisation de ces documents sont précisées sur les pages du Site concernées.
        L’ensemble des éléments figurant sur le Site sont protégés par les dispositions du Code de la Propriété
        Intellectuelle : il s’agit notamment des éléments rédactionnels figurant sur le Site, de la présentation des
        écrans, et des logiciels nécessaires à l’exploitation, les logos, images, photos, graphiques, de quelque nature
        qu’ils soient.<br>
        Toute représentation totale ou partielle du Site par quelque société que ce soit, sans l’autorisation expresse
        de l’Editeur, est interdite et constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du
        Code de la propriété intellectuelle. Il est en de même des bases de données figurant sur le Site, qui sont
        protégées par les dispositions de la loi du 1er juillet 1998 portant transposition dans le Code de la propriété
        intellectuelle de la directive européenne du 11 mars 1996 relative à la protection juridique des bases de
        données.</p>
    </div>

    <div class="box">
      <p class="subtitle">2. Lien Hypertexte :</p>
      <p>La création d’un lien hypertexte vers le Site est autorisée sans frame et exclusivement vers la page d’accueil
        du Site, sauf autorisation préalable et expresse de « l’Éditeur ». « L’Éditeur » ne peut en aucun cas être tenu
        pour responsable des informations diffusées sur les sites avec lesquels des liens hypertexte auront été
        installés ainsi que de tous préjudices de quelque nature que ce soit résultant notamment de leur accès. Vous
        êtes expressément invités à prendre toutes mesures utiles et nécessaires pour vous prémunir contre tout virus et
        autres altérations préjudiciables.</p>
    </div>

    <div class="box">
      <p class="subtitle">3. Utilisation :</p>
      <p>Toute utilisation du Site non conforme aux points ci-dessus, sauf indication contraire précisée sur le Site ou
        autorisation préalable et expresse de l’Éditeur, est interdite et engage la responsabilité de son auteur.
        Par ailleurs, l’utilisation des informations contenues sur le Site relève de la seule responsabilité de
        l’utilisateur. Nous ne pourrions en aucun cas, et pour quelque cause que ce soit, en être tenus pour
        responsables, et ce, quel qu’en soit les conséquences. Nous ne sommes responsables d’aucune erreur ou omission
        sur le Site et nous nous réservons le droit de modifier, de corriger et/ou compléter le contenu du Site à tout
        moment, sans préavis.<br>
        En utilisant le Site l’internaute accepte – sans condition ni réserve – l’intégralité des présentes conditions
        d’utilisation. Tout litige résultant de l’utilisation du Site ou de la diffusion des informations qu’il contient
        sera de la compétence exclusive des tribunaux de Paris faisant application de la loi française.</p>
    </div>

    <div class="box">
      <p class="subtitle">4. Restriction d’accès et/ou de diffusion :</p>
      <p>L’accès au Site et/ou aux informations et des documents inclus sur le Site peuvent, dans certains pays, faire
        l’objet de restrictions légales ou réglementaires. Par conséquent, les personnes qui accèdent au Site et/ou
        informations et documents qu’il contient doivent préalablement s’informer sur l’existence de telles restrictions
        et s’y conformer. Autant que permis par la loi applicable, l’Editeur décline toute responsabilité en cas de
        violation de ces restrictions par toute personne, les personnes accédant au Site assumant l’intégralité des
        conséquences de toutes violations ou manquement auxdites restrictions.</p>
    </div>

    <div class="box">
      <p class="subtitle">5. Décharge de responsabilité : </p>
      <p>L’Editeur ne pourra en aucun cas être tenu responsable pour toute perte ou dommage occasionnés par
        l’utilisation de tout ou partie des fichiers et de tout contenu mis à disposition sur le Site. Il n’est pas
        possible de certifier que tout le contenu disponible sur le Site est exempt d’erreurs, n’est pas corrompu,
        perdu, détruit, non mis à jour ou sans virus. BAKJY SARL a pris toutes les mesures raisonnables pour s’assurer
        que le contenu disponible sur le Site et ne contienne pas de virus. En revanche, l’Editeur (ou aucun de ses
        dirigeants, directeurs, employés ou agents) ne pourra être tenu responsable pour des dommages résultants de, ou
        lié à la présence de virus. Il est fortement recommandé de soumettre tous les fichiers téléchargés sur le Site
        au scan de votre propre antivirus avant toute ouverture.</p>
    </div>

    <div class="box">
      <p class="subtitle">6. Liens vers d’autres sites :</p>
      <p>Le Site peut contenir des liens ou références à des sites internet de sociétés non gérées ou contrôlées par
        BAKJY SARL. Les présentes mentions légales ne sont pas applicables à ces tiers.
        Les liens ou références aux sites précités ne suppose pas que l’Editeur ait revu ou contrôlé leurs mentions
        légales ou leur politique de confidentialité.</p>
    </div>

    <div class="box">
      <p class="subtitle">7. Collecte des données :</p>
      <p>Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où
        il ne collecte aucune donnée concernant les utilisateurs.
      </p>
    </div>

    <div class="box">
      <p class="subtitle">8. Cookies :</p>
      <p>Lors de la navigation sur notre site, des cookies sont déposés sur votre terminal (ordinateur, mobile,
        tablette), sous réserve des choix que vous aurez exprimés et que vous pouvez modifier à tout moment.<br>
        Cette page vous permet de mieux comprendre comment fonctionnent les cookies et comment utiliser les outils
        actuels afin de les paramétrer. Déposé sur votre terminal lors de la visite d’un site, un cookie est un petit
        fichier texte contenant des informations relatives à votre navigation sur ledit site, dont le principal objectif
        est d’améliorer votre consultation et de permettre de vous adresser des services personnalisés. Sur votre
        ordinateur, les cookies sont gérés par votre navigateur internet. Ces cookies peuvent être de session (dans ce
        cas, le cookie sera automatiquement supprimé lors de la fermeture du navigateur) ou persistants (dans ce cas, le
        cookie restera stocké dans votre terminal jusqu’à sa date d’expiration). Vous trouverez ci-dessous les cookies
        utilisés par le site http://www.bakjy.com :</p>
    </div>

    <div class="box">
      <p class="subtitle">Cookies de mesure d’audience:</p>
      <p>Ces cookies permettent de reconnaître les internautes au cours de leurs différentes visites et de déterminer le
        nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site
        internet. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les
        visiteurs naviguent sur le site internet<br>
        Comment accepter ou refuser les cookies ?<br>
        Vous pouvez configurer votre navigateur de manière à ce que les cookies soient enregistrés dans votre terminal
        ou rejetés, soit systématiquement, soit selon leur émetteur, ou pour être informé(e) au moment où un cookie est
        enregistré dans votre terminal, afin de vous permettre de l’accepter ou le refuser. Cependant, la suppression de
        tous les cookies utilisés par le navigateur, y compris ceux employés par d’autres sites web, ce qui peut
        conduire à l’altération ou la perte de certains réglages ou informations et à rendre difficile la navigation sur
        le site web. La configuration de chaque navigateur est différente. Il vous appartient de suivre les instructions
        de l’éditeur de votre navigateur comme suit (liens disponibles à la date de mise à jour de cette page):<br><br>
        • Si vous utilisez Internet Explorer :<br>
        https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies<br>
        • Si vous utilisez Safari : https://support.apple.com/kb/ph17191?locale=fr_FR&viewlocale=fr_FR<br>
        • Si vous utilisez Chrome : https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=fr<br>
        • Si vous utilisez Opera : http://help.opera.com/Windows/10.20/fr/cookies.html<br>
        • Si vous utilisez différents terminaux, assurez-vous de paramétrer le navigateur correspondant de chacun selon
        vos préférences.<br>
        Si vous ne souhaitez pas modifier la configuration des cookies, poursuivez simplement votre visite sur le site.
        Pour en savoir plus sur les cookies et leur incidence, nous vous invitons à consulter la rubrique « Vos traces »
        du site de la Commission Nationale de l’Informatique et des Libertés :<br>
        http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/.</p>
    </div>

    <div class="box">
      <p class="subtitle">9. Modification des présentes mentions légales :</p>
      <p>Les présentes mentions légales correspondent à nos standards actuels qui peuvent faire l’objet de
        modifications. Nous nous réservons en conséquence le droit de modifier les présentes mentions légales.
        <br><br>
        Tout changement prendra effet à compter de la publication de la dernière version mise à jour sur le Site.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Legale"
}
</script>

<style scoped>
.container {
  margin-top: 2rem;
}
</style>