<template>
  <Legale></Legale>
</template>

<script>
import Legale from "@/components/Legale";

export default {
  name: "MentionsLegales",
  components: {
    Legale,
  }
}
</script>

<style scoped>

</style>